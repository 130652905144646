import React, { Component } from "react"
import Layout from "../components/Layout/layout"

class AboutPage extends Component {
  render() {
    let aboutData = this.props.data.allAboutJson.nodes[0]
    

    return (
      <Layout page={"about"}>
        <div className="main">
          <h1 className="title">{aboutData.title}</h1>
          <div className="text">{aboutData.text}</div>

          <div>
            <h2 className="subtitle">{aboutData.subtitle}</h2>
          </div>
          
        <div className="about-grid-container">
          <div className="about-grid-item-left">
          <img src={aboutData.image} alt="about" className="about-icon"></img>
          </div>
          <div className="about-grid-item-right">
            <img
              src={aboutData.name_icon}
              alt="Name"
              className="info-icon"
            ></img>
            {aboutData.name}
            <br />
            <span>
              <img
                src={aboutData.mail_icon}
                alt="Mail"
                className="info-icon"
              ></img>
              {aboutData.email}
            </span>
            <br />
            <span>
              <a
              href={aboutData.github_link}>
              <img
                src={aboutData.github_icon}
                alt="Github"
                className="info-icon"
              ></img></a>
              {aboutData.github}
            </span>
            <br />
            <span>
            <a
              href={aboutData.linkedin_link}>
              <img
                src={aboutData.linkedin_icon}
                alt="Linkedin"
                className="info-icon"
              ></img></a>
              {aboutData.linkedin}
            </span>
            <br />
            <span>
              <img
                src={aboutData.title_icon}
                alt="Title"
                className="info-icon"
              ></img>
              
              <a href={aboutData.resume_link} download>Download my resume</a>
            </span>
          </div>
        </div>
      
        </div>
      </Layout>
    )
  }
}
export default AboutPage

export const query = graphql`
  query {
    allAboutJson {
      nodes {
        id
        title
        description
        text
        subtitle
        name_icon
        title_icon
        mail_icon
        github_icon
        linkedin_icon
        resume_icon
        name
        email
        github
        github_link
        image
        linkedin
        linkedin_link
        resume_link
      }
    }
  }
`
